<!-- <app-list></app-list> -->
<!-- start page title -->
<!-- <app-pagetitle title="Licence List" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->
<!-- end page title -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <h5 class="card-title">Licence List <span class="text-muted fw-normal ms-2"></span>
                            </h5>
                        </div>
                    </div><!-- end col -->
                    <div class="col-md-6">
                        <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                            <!-- <div>
                                <ul class="nav nav-pills">
                                    <li class="nav-item">
                                        <a class="nav-link active" routerLink="/contacts/list" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="List"><i class="uil uil-list-ul"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="/contacts/grid" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Grid"><i class="uil uil-apps"></i></a>
                                    </li>
                                </ul>
                            </div> -->
                            <div>
                                <!-- <a href="javascript:void(0);" class="btn btn-light" data-bs-toggle="modal"
                                    data-bs-target="#addContactModal" (click)="openModal(content)"><i
                                        class="uil uil-plus me-1"></i> Add
                                    New</a> -->
                                <!-- Modal -->
                                <!-- <ng-template #content role="document" let-modal>
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addContactModalLabel">Add Contact</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                                    </div>
                                    <form (ngSubmit)="saveUser()" [formGroup]="userForm">
                                        <div class="modal-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="mb-3">
                                                        <label for="addcontact-name-input"
                                                            class="form-label">Name</label>
                                                        <input class="form-control" placeholder="Enter Name" type="text"
                                                            name="name" formControlName="name"
                                                            [ngClass]="{'is-invalid': submitted && form.name.errors}" />
                                                        <div *ngIf="submitted && form.name.errors"
                                                            class="invalid-feedback">
                                                            <span *ngIf="form.name.errors.required">Name is
                                                                required.</span>
                                                        </div>
                                                    </div>

                                                    <div class="mb-3">
                                                        <label for="addcontact-designation-input"
                                                            class="form-label">Designation</label>
                                                        <input class="form-control" placeholder="Enter designation"
                                                            type="text" name="designation" formControlName="designation"
                                                            [ngClass]="{'is-invalid': submitted && form.designation.errors}" />
                                                        <div *ngIf="submitted && form.designation.errors"
                                                            class="invalid-feedback">
                                                            <span *ngIf="form.designation.errors.required">Designation
                                                                is required.</span>
                                                        </div>
                                                    </div>

                                                    <div class="mb-3">
                                                        <label for="addcontact-email-input"
                                                            class="form-label">Email</label>
                                                        <input class="form-control" placeholder="Enter Email"
                                                            type="text" name="email" formControlName="email"
                                                            [ngClass]="{'is-invalid': submitted && form.email.errors}" />
                                                        <div *ngIf="submitted && form.email.errors"
                                                            class="invalid-feedback">
                                                            <span *ngIf="form.email.errors.required">Email
                                                                is required.</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-light w-sm"
                                                (click)="modal.close('Close click')">Close</button>
                                            <button type="submit" class="btn btn-primary w-sm"
                                                id="btn-save-event">Add</button>
                                        </div>
                                    </form>
                                </ng-template> -->
                                <!--End Modal -->
                            </div>

                            <!-- <div class="dropdown" ngbDropdown>
                                <a class="btn btn-link text-dark dropdown-toggle shadow-none" href="javascript:void(0);"
                                    role="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                    <i class="uil uil-ellipsis-h"></i>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                    <li><a class="dropdown-item" href="javascript:void(0);">Action</a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0);">Another action</a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0);">Something else here</a></li>
                                </ul>
                                 end ul
                            </div> -->
                        </div>
                    </div><!-- end col -->
                </div><!-- end row -->

                <div class="row">
                    <div class="col-12">
                        <div class="border_none">
                            <div class="py-2">
                                <div class="row mb-md-2">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="dataTables_length" id="tickets-table_length"><label
                                                class="d-inline-flex align-items-center">Show
                                                <select name="tickets-table_length" aria-controls="tickets-table"
                                                    name="pageSize" [(ngModel)]="service.pageSize"
                                                    class="form-control form-control-sm mx-2">
                                                    <option [ngValue]="10">10</option>
                                                    <option [ngValue]="25">25</option>
                                                    <option [ngValue]="50">50</option>
                                                    <option [ngValue]="100">100</option>
                                                </select> entries</label></div>
                                    </div>
                                    <!-- Search -->
                                    <div class="col-sm-12 col-md-6">
                                        <div>

                                        </div>
                                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                            <label class="padding"> License Requests Only
                                                <input type="checkbox" class="margin"
                                                    (click)="licenceRequest($event)"></label>
                                            <label class="d-inline-flex align-items-center">Search:
                                                <input type="text" name="searchTerm"
                                                    class="form-control form-control-sm ms-2"
                                                    aria-controls="tickets-table"
                                                    (keyup)="setSearchVal($event)"></label>
                                        </div>
                                    </div>
                                    <!-- End search -->
                                </div>
                                <!-- Table -->
                                <div class="table-responsive datatables">
                                    <!-- datatables -->
                                    <table class="table align-middle table-nowrap table-check">
                                        <thead>
                                            <tr class="text-dark">
                                                <th (click)="sort('userName')"
                                                    [ngClass]="{'asc': isDesc && column == 'userName', 'desc': !isDesc && column == 'userName'}">
                                                    Name</th>
                                                <th (click)="sort('emailID')"
                                                    [ngClass]="{'asc': isDesc && column == 'emailID', 'desc': !isDesc && column == 'emailID'}">
                                                    Email</th>
                                                <th (click)="sort('autoDeskID')" 
                                                    [ngClass]="{'asc': isDesc && column == 'autoDeskID', 'desc': !isDesc && column == 'autoDeskID'}">
                                                    AutoDeskID</th>
                                                <th (click)="sort('machineName')"
                                                    [ngClass]="{'asc': isDesc && column == 'machineName', 'desc': !isDesc && column == 'machineName'}">
                                                    Machine Name</th>
                                                <th (click)="sort('company')"
                                                    [ngClass]="{'asc': isDesc && column == 'company', 'desc': !isDesc && column == 'company'}">
                                                    Company</th>
                                                <th (click)="sort('isApproved')"
                                                    [ngClass]="{'asc': isDesc && column == 'isApproved', 'desc': !isDesc && column == 'isApproved'}">
                                                    Licensed</th>
                                                <th (click)="sort('isAdminUser')"
                                                    [ngClass]="{'asc': isDesc && column == 'isAdminUser', 'desc': !isDesc && column == 'isAdminUser'}">
                                                    User Type</th>
                                                <!-- <th (click)="sort('position')" *ngIf="loginDetails.role == LOGIN_DETAILS.SUPER_ADMIN"
                                                    [ngClass]="{'asc': isDesc && column == 'position', 'desc': !isDesc && column == 'position'}">
                                                    Promote / Demote</th> -->
                                                <th class="actions sort-action" >Actions</th>
                                            </tr><!-- end tr -->
                                        </thead><!-- end thead -->
                                        <tbody *ngFor="let table of licenceData;let i=index">
                                            <tr>
                                                <td class="word_overflow">
                                                    <!-- <img src="{{table.image}}" alt=""
                                                            class="avatar-sm rounded-circle me-2"> -->
                                                    <a>
                                                        <ngb-highlight [result]="table.userName"
                                                            [title]="table.userName" [term]="service.searchTerm">
                                                        </ngb-highlight>
                                                    </a>
                                                </td>
                                                <td class="word_overflow">
                                                    <ngb-highlight [result]="table.emailID" [term]="service.searchTerm"
                                                        [title]="table.emailID">
                                                    </ngb-highlight>
                                                </td>
                                                <td class="word_overflow">
                                                    <ngb-highlight [result]="table.autoDeskID"
                                                        [term]="service.searchTerm" [title]="table.autoDeskID">
                                                    </ngb-highlight>
                                                </td>
                                                <td class="word_overflow">
                                                    <p *ngIf="!table.machineName" style="margin-left: 40px;"> - </p>
                                                    <ngb-highlight [result]="table.machineName"
                                                        [term]="service.searchTerm">
                                                    </ngb-highlight>
                                                </td>
                                                <td class="word_overflow">
                                                    <p *ngIf="!table.company" style="margin-left: 40px;"> - </p>
                                                    <ngb-highlight [result]="table.company" [term]="service.searchTerm">
                                                    </ngb-highlight>
                                                </td>
                                                <td class="word_overflow">
                                                    <a *ngIf="table.isApproved == true"
                                                        style="margin-left: 20px;">Yes</a>
                                                    <div *ngIf="(table.isApproved == false)">
                                                        <button type="button" class="btn btn-success btn-sm"
                                                            style="margin-right: 5px"
                                                            (click)="confirmModal(confirm_content,table,LICENCE_NAME.APPROVE_LICENCE)">
                                                            <i class="bi bi-check"></i></button>
                                                        <button type="button" class="btn btn-danger btn-sm"
                                                            (click)="confirmModal(confirm_content,table,LICENCE_NAME.REMOVE_USER)">
                                                            <i class="bi bi-x"></i></button>
                                                    </div>
                                                </td>
                                                <td class="word_overflow">
                                                    <a>{{table.isAdminUser == true ? 'Admin' : 'User'}}</a>
                                                </td>
                                                <td class="word_overflow">
                                                    <div [class]="(loginDetails.role == LOGIN_DETAILS.SUPER_ADMIN ? 'actionicons' : 'adminAction')">
                                                        <div
                                                            *ngIf="(loginDetails.role == LOGIN_DETAILS.SUPER_ADMIN)">
                                                            <div *ngIf="table.isAdminUser == false">
                                                                <img src="../../../../../assets/images/make-admin.png" title="Promote to Admin"
                                                                    class="iconsize"
                                                                    (click)="confirmModal(confirm_content,table,LICENCE_NAME.ADMIN)"
                                                                   >
                                                            </div>
                                                            <div *ngIf="table.isAdminUser == true">
                                                                <img src="../../../../../assets/images/make-user.png" title="Demote to User"
                                                                    class="iconsize"
                                                                    (click)="confirmModal(confirm_content,table,LICENCE_NAME.ADMIN)"
                                                                    >
                                                            </div>
                                                        </div>
                                                        <!-- <button  [class]="table.isAdminUser == false ? 'btn btn-sm btn-success' : 'btn btn-sm btn-danger'" style="width: 75px;"
                                                            (click)="confirmModal(confirm_content,table,LICENCE_NAME.ADMIN)">{{table.isAdminUser
                                                            == true ? ' Demote ' : 'iconsize'}}</button> -->

                                                        <div
                                                            *ngIf="(loginDetails.role == LOGIN_DETAILS.SUPER_ADMIN || (loginDetails.role == LOGIN_DETAILS.ADMIN && table.isAdminUser == false))">
                                                            <div *ngIf="table.status == false">
                                                                <img src="../../../../../assets/images/slider-disabled.png" title="Enable"
                                                                    class="iconsize"
                                                                    (click)="changeStatus(table)"
                                                                   >
                                                            </div>
                                                            <div *ngIf="table.status == true">
                                                                <img src="../../../../../assets/images/slider-enabled.png" title="Disable"
                                                                    class="iconsize"
                                                                    (click)="changeStatus(table)"
                                                                   >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="isDataReceived && !licenceData.length">
                                            <tr>
                                                <td colspan="8" style="text-align: center;">No data found</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                                <!-- End table -->
                                <div class="row justify-content-md-between align-items-md-center mt-2">
                                    <div class="col-sm-12 col-md-5">
                                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status"
                                            aria-live="polite">
                                            Showing
                                            {{service.startIndex}} to
                                            {{service.endIndex}} of {{service.totalRecords}}
                                            entries
                                        </div>
                                    </div>
                                    <!-- Pagination -->
                                    <div class="col-sm-12 col-md-5">
                                        <div class="text-md-right float-md-end pagination-rounded">
                                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                                [pageSize]="service.pageSize" [maxSize]="3" [ellipses]="true">
                                            </ngb-pagination>
                                        </div>
                                    </div>
                                    <!-- End Pagination -->
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <app-notification></app-notification>
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->
<ng-template #confirm_content role="document" let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0"> {{alertHeader}}</h5>
        <button type="button" class="btn-close" aria-hidden="true" (click)="closeDialogs()"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <h5 *ngIf="alertBodyMsg != LICENCE_ALERT_MSG.USER_DEPROMOTE_MSG">{{alertBodyMsg}}</h5><br *ngIf="alertBodyMsg == LICENCE_ALERT_MSG.LICENCE_REMOVE_MSG"><br *ngIf="alertBodyMsg == LICENCE_ALERT_MSG.LICENCE_REMOVE_MSG">
        <h5 *ngIf="alertBodyMsg == LICENCE_ALERT_MSG.USER_DEPROMOTE_MSG">Are you sure you want to demote "{{selectedData.userName}}" to a user?</h5>
        <h5 *ngIf="alertBodyMsg == LICENCE_ALERT_MSG.LICENCE_REMOVE_MSG">
            {{'Note: The user will be removed from the license list'}}</h5>
        <form [formGroup]="lockscreenForm"><br>
            <h6 *ngIf="changeType == LICENCE_NAME.ADMIN">{{'Please provide a password for admin.'}}</h6>
            <div *ngIf="changeType == LICENCE_NAME.ADMIN" class="form-floating form-floating-custom mb-3">
                <input type="password" class="form-control" aria-describedby="password-addon"
                    formControlName="password">
                <label for="input-password">Password</label>
                <div class="form-floating-icon">
                    <i class="uil uil-padlock"></i>
                </div>
                <!-- <div *ngIf="submitted && lockscreenForm.controls.password.errors" class="invalid-feedback" align="left"> -->
                <span
                    *ngIf="submitted && lockscreenForm.controls.password.errors && lockscreenForm.controls.password.errors.required">
                    <p class="text-danger">Password is
                        required.</p>
                </span>
                <span class="text-danger"
                    *ngIf="submitted && lockscreenForm?.controls?.password?.errors?.minlength">Password should contain
                    minimum 8 charecters</span>
                <span class="text-danger"
                    *ngIf="submitted && lockscreenForm?.controls?.password?.errors?.maxlength">Password should not
                    exceed more then 15 charecters</span>
                <!-- </div> -->
            </div>
            <div class="modal-footer" *ngIf="changeType == LICENCE_NAME.ADMIN ">
                <div class="pull-right">
                    <button type="button" class="btn btn-danger" style="margin-right: 5px" (click)="closeDialogs()"
                        (click)="modal.dismiss('Cross click')"> Close</button>
                    <button type="submit" class="btn btn-success" (click)="alertDialogs()">Save</button>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" *ngIf="changeType != LICENCE_NAME.ADMIN">
        <a href="javascript:void(0);" class="btn btn-outline-danger" (click)="cancel()">{{alertDecline}}</a>
        <a href="javascript:void(0);" class="btn btn-outline-primary" type="submit" (click)="alertDialogs()"
            (click)="modal.dismiss('Cross click')">{{alertSubmit}}</a>
    </div>
</ng-template>
<app-notification></app-notification>