export enum ALERT_MSG {
    ADMIN_HEADER_MSG = 'Change Status',
    ADMIN_PROMOTE_MSG ='The admin needs a password to access the account',
    USER_DEPROMOTE_MSG = 'Are you sure do you want to demote this adminstrator to a user?',
    LICENCE_ALLOW_MSG = 'Are you sure you want to grant this user a License?',
    LICENCE_REMOVE_MSG = 'Are you sure you want to decline the selected user a licence?',
    ENABLE_USER_MSG = 'Are you sure do you want to enable this user?',
    DISABLE_USER_MSG = 'Are you sure do you want to disable this user?',
    APPROVE_LICENCE_MSG = 'Approve License',
    DECLINE_LICENCE_MSG = 'Decline License',
    CANCEL = "Cancel",
    PROMOTE = 'Promote',
    DEMOTE = 'Demote',
    NO = 'No',
    YES = 'Yes'
}
export enum LICENCE_PERMISSION_NAME {
    APPROVE_LICENCE = 'Approve',
    REMOVE_USER = 'Remove',
    ADMIN = 'Admin',
    USER = 'User',
    STATUS = 'status',
    VALID = 'VALID',
    INVALID = 'INVALID',
}