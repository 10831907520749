import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { UserList } from 'src/app/pages/users/user-interface';
import { UserSortableDirective } from '../../list/list-sortable.directive';
import { UserListService } from '../../list/list.service';
import { ALERT_MSG, LICENCE_PERMISSION_NAME } from '../../licence.enum';
import { ADMIN_DETAILS } from 'src/app/app-routes-names';

@Component({
  selector: 'app-license-list-template',
  templateUrl: './license-list-template.component.html',
  styleUrls: ['./license-list-template.component.scss'],

})
export class LicenseListTemplateComponent implements OnInit {
  // bread crumb items
  breadCrumbItems!: Array<{}>;
  tables$!: Observable<any[]>;
  total$: Observable<number>;
  userForm!: FormGroup;
  lockscreenForm!: FormGroup;
  tableData!: UserList[];
  submitted = false;
  userDeclined: boolean = false;
  LICENCE_ALERT_MSG: any = ALERT_MSG;
  LICENCE_NAME: any = LICENCE_PERMISSION_NAME;
  alertHeaderMsg: any;
  alertBodyMsg: any;

  @ViewChildren(UserSortableDirective) headers!: QueryList<UserSortableDirective>;

  @Output() userDetails: EventEmitter<any> = new EventEmitter();

  @Input() isDataReceived: boolean = false;
  masterSelected: boolean;
  checklist: any;
  checkedList: any;

  isDesc: boolean = false;
  column: any = 'name';
  licenceData: any;
  selectedData: any;
  changeType: any;
  loginDetails: any;
  LOGIN_DETAILS: any = ADMIN_DETAILS;
  tempLicenseData: any[] = [];
  alertHeader: any;
  alertSubmit: any;
  alertDecline: any;
  sortData: any;
  licenceRequestData: any;
  licenseRequiested: boolean = false;

  @Input() public set licenceListData(data: any) {
    if (!data) { return; }
    this.service.tableRecords = data;
    this.licenceData = data;
    this.licenceRequestData = Object.assign(data);
    this.sortData = Object.assign(data);
    this.tempLicenseData = JSON.parse(JSON.stringify(data));
  }

  @Input() public set userLoginData(data: any) {
    if (!data) { return; }
    this.loginDetails = data;
    console.log(data)
  }
  constructor(public service: UserListService, private modalService: NgbModal, private formBuilder: FormBuilder) {
    this.total$ = service.total$;
    this.tables$ = service.tables$;
    this.masterSelected = false;
  }

  ngOnInit(): void {
    /**
     * BreadCrumb 
     */
    this.service.tables$.subscribe((res: any) => {
      if (!this.licenseRequiested) {
        this.licenceData = res;
      }
      if (this.licenseRequiested) {
        let data: any = new Array();
        this.licenceData = res.forEach((element: any) => {
          if (element.isApproved == false) {
            data.push(element);
          }
        });
        this.licenceData = data
      }
    });

    this.breadCrumbItems = [
      { label: 'Contacts' },
      { label: 'User List', active: true }
    ];

    /**
     * Form Validation
     */
    this.userForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      designation: ['', [Validators.required]],
      email: ['', [Validators.required]]
    });

    this.lockscreenForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]]
    });
    /**
     * fetches data
     */
    this._fetchData();
  }

  /**
   * User grid data fetches
   */
  private _fetchData() {
    console.log(this.tables$)
    // this.tableData = tableData;
  }

  /**
   * Open modal
   * @param content modal content
   */
  openModal(content: any) {
    this.submitted = false;
    this.modalService.open(content, { centered: true });
  }

  /**
   * Sort data
   * @param property 
   */
  sort(property: string | number) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.sortData.sort((a: any, b: any) => {
      if (((a[property] == null || typeof a[property] == 'boolean' || typeof a[property] == 'number') ? a[property] : a[property].toLowerCase()) < ((b[property] == null || typeof b[property] == 'boolean' || typeof b[property] == 'number') ? b[property] : b[property].toLowerCase())) {
        return -1 * direction;
      }
      else if (((a[property] == null || typeof a[property] == 'boolean' || typeof a[property] == 'number') ? a[property] : a[property].toLowerCase()) > ((b[property] == null || typeof b[property] == 'boolean' || typeof b[property] == 'number') ? b[property] : b[property].toLowerCase())) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };

  setSearchVal(event: any) {
    this.service.searchTerm = event.target.value;
  }
  // The master checkbox will check/ uncheck all items
  checkUncheckAll() {
    for (var i = 0; i < this.tableData.length; i++) {
      this.tableData[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  // Check All Checkbox Checked
  isAllSelected() {
    this.masterSelected = this.tableData.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }

  // Get List of Checked Items
  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.tableData.length; i++) {
      if (this.tableData[i].isSelected)
        this.checkedList.push(this.tableData[i]);
    }
    this.checkedList = JSON.stringify(this.checkedList);
  }

  licenceRequest(event: any) {
    if (event.target.checked) {
      this.licenseRequiested = true;
      // this.licenceData = this.licenceData.filter((x: any) =>  !x.isApproved);
      // this.sortData =  this.licenceData;

    } else {
      this.licenseRequiested = false;
      // this.licenceData = this.licenceRequestData;
      // this.sortData =  this.licenceRequestData;
    }
  }
  confirmModal(content: any, data: any, property: any) {
    this.selectedData = data;
    this.changeType = property;
    if (property == this.LICENCE_NAME.ADMIN) {
      this.alertBodyMsg = data.isAdminUser ? this.LICENCE_ALERT_MSG.USER_DEPROMOTE_MSG : this.LICENCE_ALERT_MSG.ADMIN_PROMOTE_MSG;
      this.alertHeader = data.isAdminUser ? this.LICENCE_ALERT_MSG.DEMOTE : this.LICENCE_ALERT_MSG.PROMOTE;
      this.alertDecline = this.LICENCE_ALERT_MSG.NO;
      this.alertSubmit = this.LICENCE_ALERT_MSG.YES;
      if (data.isAdminUser == true) { this.changeType = this.LICENCE_NAME.USER; }
    } else if (property == this.LICENCE_NAME.APPROVE_LICENCE) {
      this.alertHeader = this.LICENCE_ALERT_MSG.APPROVE_LICENCE_MSG;
      this.alertBodyMsg = this.LICENCE_ALERT_MSG.LICENCE_ALLOW_MSG;
      this.alertSubmit = this.LICENCE_ALERT_MSG.APPROVE_LICENCE_MSG;
      this.alertDecline = this.LICENCE_ALERT_MSG.CANCEL;
    } else if (property == this.LICENCE_NAME.REMOVE_USER) {
      this.alertHeader = this.LICENCE_ALERT_MSG.DECLINE_LICENCE_MSG;
      this.alertBodyMsg = this.LICENCE_ALERT_MSG.LICENCE_REMOVE_MSG;
      this.alertSubmit = this.LICENCE_ALERT_MSG.DECLINE_LICENCE_MSG;
      this.alertDecline = this.LICENCE_ALERT_MSG.CANCEL;
    }
    this.modalService.open(content);
  }

  alertDialogs() {
    if (this.changeType == this.LICENCE_NAME.ADMIN && this.lockscreenForm.invalid) {
      this.submitted = true;
      return;
    }
    if (this.changeType == this.LICENCE_NAME.APPROVE_LICENCE || this.changeType == this.LICENCE_NAME.REMOVE_USER) {
      let changeLicence = (this.changeType == this.LICENCE_NAME.APPROVE_LICENCE) ? true : false;
      if (changeLicence) { this.userDeclined = true }
      this.changeApprovalStatus(changeLicence);
    } else if (this.lockscreenForm.status == this.LICENCE_NAME.VALID) {
      this.changeAdmin(this.lockscreenForm.value.password);
    } else if (this.changeType == this.LICENCE_NAME.ADMIN || this.changeType == this.LICENCE_NAME.USER) {
      this.changeAdmin(this.changeType == this.LICENCE_NAME.ADMIN ? this.lockscreenForm.value.password : this.changeType)
    }
    this.lockscreenForm.reset();
  }

  closeDialogs() {
    this.submitted = false;
    this.lockscreenForm.reset();
  }

  cancel() {
    this.modalService.dismissAll();
  }

  changeApprovalStatus(data: boolean) {
    this.licenceData.map((x: any) => {
      x.isApproved = (x.userID == this.selectedData.userID ? data : x.isApproved);
      return x;
    })
    let userData = {
      userID: this.selectedData.userID,
      Status: data,
      Type: 1,
    }
    this.userDetails.emit(userData)
  }

  changeAdmin(data: any) {
    let userData: any;
    this.licenceData.map((x: any) => {
      x.isAdminUser = (x.userID == this.selectedData.userID ? !this.selectedData.isAdminUser : x.isAdminUser);
      return x;
    })
    if (data == this.LICENCE_NAME.USER) {
      userData = {
        UserID: this.selectedData.userID,
        Status: this.selectedData.isAdminUser,
        Type: 3,
      }
    } else {
      userData = {
        UserID: this.selectedData.userID,
        Status: this.selectedData.isAdminUser,
        Type: 3,
        Password: data
      }
    }

    this.modalService.dismissAll();
    this.userDetails.emit(userData);
  }

  changeStatus(data: any) {
    this.selectedData = data;
    this.licenceData.map((x: any) => {
      x.status = (x.userID == this.selectedData.userID ? !this.selectedData.status : x.status);
      return x;
    })
    let userData = {
      UserID: this.selectedData.userID,
      Status: this.selectedData.status,
      Type: 2
    }
    this.userDetails.emit(userData)
  }

}
