<!-- start page title -->
<app-pagetitle title="Form Advanced" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header justify-content-between d-flex align-items-center">
                <h4 class="card-title">Css Switch</h4>
                <p class="m-0 badge badge-soft-primary py-2">Frame Tech Only</p>
            </div><!-- end card header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-12">
                        <h5 class="font-size-14 mb-3">Example Switch</h5>
                        <div class="d-flex flex-wrap gap-2">
                            <ui-switch defaultBoColor="#dfdfdf" color="#038edc" [checked]="true" checkedLabel="On"
                                uncheckedLabel="Off"></ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#a2a2a2" [checked]="true" checkedLabel="On"
                                uncheckedLabel="Off"></ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [checked]="true" checkedLabel="On"
                                uncheckedLabel="Off"></ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#038edc" [checked]="true" checkedLabel="On"
                                uncheckedLabel="Off"></ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#5fd0f3" [checked]="true" checkedLabel="On"
                                uncheckedLabel="Off"></ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#f7cc53" [checked]="true" checkedLabel="On"
                                uncheckedLabel="Off"></ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#f34e4e" [checked]="true" checkedLabel="On"
                                uncheckedLabel="Off"></ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#343a40" [checked]="true" checkedLabel="On"
                                uncheckedLabel="Off"></ui-switch>
                        </div>
                    </div><!-- end col -->
                </div><!-- end row -->
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-6">
        <div class="card">
            <div class="card-header justify-content-between d-flex align-items-center">
                <h4 class="card-title">Colorpicker</h4>
                <a href="https://www.npmjs.com/package/ngx-color-picker" target="_blank" class="btn btn-sm btn-soft-secondary">Docs
                    <i class="mdi mdi-arrow-right align-middle"></i></a>
            </div><!-- end card header -->
            <div class="card-body">
                <div class="text-center">
                    <div class="row">
                        <div class="col-4">
                            <div class="">
                                <h5 class="font-size-14">Classic Demo</h5>
                                <div class="color_picker">
                                    <span class="input-group-text colorpicker-input-addon"
                                        [style.background]="componentcolor" [(colorPicker)]="componentcolor"
                                        [cpPosition]="'bottom'"><i></i></span>
                                </div>
                            </div>
                        </div><!-- end col -->
                        <div class="col-4">
                            <div class="">
                                <h5 class="font-size-14">Preset color</h5>
                                <div class="color_picker">
                                    <span class="input-group-text colorpicker-input-addon"
                                        id="colorpicker-showpaletteonly" [style.background]="monolith"
                                        [(colorPicker)]="monolith" [cpPosition]="'bottom'"
                                        [cpPresetColors]="['#fff','#000','#2889e9','#e920e9','#fff500','rgb(236,64,64)']"></span>
                                </div>
                            </div>
                        </div><!-- end col -->
                        <div class="col-4">
                            <div class="">
                                <h5 class="font-size-14">Nano Demo</h5>
                                <div class="color_picker">
                                    <span class="input-group-text colorpicker-input-addon"
                                        id="colorpicker-showpaletteonly" [cpColorMode]="'grayscale'"
                                        [(colorPicker)]="nano" [cpPosition]="'bottom-left'"></span>
                                </div>
                            </div>
                        </div><!-- end col -->
                    </div><!-- end row -->
                </div>
            </div><!-- end card body-->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header justify-content-between d-flex align-items-center">
                <h4 class="card-title">Select</h4>
                <a href="https://www.npmjs.com/package/@ng-select/ng-select" target="_blank" class="btn btn-sm btn-soft-secondary">Docs <i
                        class="mdi mdi-arrow-right align-middle"></i></a>
            </div><!-- end card header -->
            <div class="card-body">
                <div>
                    <h5 class="font-size-14 mb-3">Single select input Example</h5>

                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="mb-3">
                                <label for="choices-single-default"
                                    class="form-label font-size-13 text-muted">Default</label>
                                <ng-select [items]="Default" bindLabel="name" [(ngModel)]="selectedAccount">
                                    <ng-template ng-optgroup-tmp let-item="item">
                                        {{item.country || 'Unnamed group'}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="mb-3">
                                <label for="choices-single-groups" class="form-label font-size-13 text-muted">Option
                                    groups</label>
                                <ng-select [items]="Groups" bindLabel="name" bindValue="name" groupBy="country"
                                    [(ngModel)]="selectedGroup">
                                    <ng-template ng-optgroup-tmp let-item="item">
                                        {{item.country || 'Unnamed group'}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="mb-3">
                                <label for="choices-single-no-search" class="form-label font-size-13 text-muted">Options
                                    added
                                    via config with no search</label>
                                <ng-select [items]="Options" bindLabel="name" [(ngModel)]="selectedOption">
                                </ng-select>
                            </div>
                        </div>

                    </div>
                    <!-- end row -->
                </div>
                <!-- Single select input Example -->

                <div class="mt-4">
                    <h5 class="font-size-14 mb-3">Multiple select input</h5>

                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="mb-3">
                                <label for="choices-multiple-default"
                                    class="form-label font-size-13 text-muted">Default</label>
                                <div class="multi_default">
                                    <ng-select [items]="Default" [multiple]="true" bindLabel="name"
                                        [(ngModel)]="multiDefaultOption">
                                        <ng-template ng-optgroup-tmp let-item="item">
                                            {{item.country || 'Unnamed group'}}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="mb-3">
                                <label for="choices-multiple-remove-button"
                                    class="form-label font-size-13 text-muted">With
                                    remove button</label>
                                <ng-select [items]="selectValue" [multiple]="true"></ng-select>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="mb-3">
                                <label for="choices-multiple-groups" class="form-label font-size-13 text-muted">Option
                                    groups</label>
                                <ng-select [items]="Groups" [multiple]="true" bindLabel="name" bindValue="name"
                                    groupBy="country">
                                    <ng-template ng-optgroup-tmp let-item="item">
                                        {{item.country || 'Unnamed group'}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>

                    </div>
                    <!-- end row -->
                </div>
                <!-- multi select input Example -->

                <div class="mt-4">
                    <h5 class="font-size-14 mb-3">Text inputs</h5>
                    <div>
                        <label for="choices-text-disabled" class="form-label font-size-13 text-muted">Disabled</label>
                        <ng-select [items]="Default" bindLabel="name" [multiple]="true" [disabled]="disable"
                            [(ngModel)]="selectedPeople1">
                        </ng-select>
                    </div>
                </div>
            </div>
            <!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header justify-content-between d-flex align-items-center">
                <h4 class="card-title">Datepicker</h4>
                <a href="https://www.npmjs.com/package/angularx-flatpickr" target="_blank" class="btn btn-sm btn-soft-secondary">Docs
                    <i class="mdi mdi-arrow-right align-middle"></i></a>
            </div><!-- end card header -->
            <div class="card-body">
                <form action="#">
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="mb-3">
                                <label class="form-label">Basic</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr>
                            </div>

                            <div class="mb-3">
                                <label class="form-label">DateTime</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr [enableTime]="true"
                                    dateFormat="Y-m-dTH:i">
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Human-friendly Dates</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true"
                                    [convertModelValue]="true">
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Disabling Dates</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr
                                    [disable]="disabledDate">
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Selecting multiple dates</label>
                                <input class="form-control flatpickr-input" type="text" id="example-week-input"
                                    mwlFlatpickr mode="multiple">
                            </div>

                            <div class="mb-3">
                                <label class="form-label">Range</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr mode="range">
                            </div>
                        </div><!-- end col -->

                        <div class="col-xl-6">
                            <div class="mt-3 mt-lg-0">
                                <div class="mb-3">
                                    <label class="form-label">Timepicker</label>
                                    <input class="form-control flatpickr-input" type="text" mwlFlatpickr
                                        [noCalendar]="true" [enableTime]="true" [dateFormat]="'H:i'">
                                </div>
                                <div>
                                    <label class="form-label">Inline</label>
                                    <input class="form-control flatpickr-input" type="text" mwlFlatpickr
                                        [altInput]="true" [convertModelValue]="true" [inline]="true">
                                </div>
                            </div>
                        </div><!-- end col -->
                    </div><!-- end row -->
                </form><!-- end form -->
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->