import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {
  encryptSecretKey: any = '1234567abcdef';

  constructor(private apiService: ApiService) { }
  

  changePassword(oldPassword: string, newPassword: string) {
    let data = {
      oldPassword : oldPassword,
      newPassword : newPassword
    }
    return this.apiService.getService('User/ChangePassword', {params: data})

        .pipe(map(res => {
            if (!res._isSucceed) {
                throw new Error(res._msg);
            }
            if (res && res._data.length && res._data[0].accessToken) {
                // this.encodeUserData(res._data[0])
            }
            // this.currentUser = res._data[0];
            return res;
        }));
}
public encodeUserData(userData: any) {

    const currentUser = CryptoJS.AES.encrypt(JSON.stringify(userData), this.encryptSecretKey).toString();
    sessionStorage.setItem('currentUser', currentUser);
    return currentUser;
}

}
