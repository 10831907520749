import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';

import { NgbDropdownModule, NgbNavModule, NgbTooltipModule, NgbTypeaheadModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '../../shared/shared.module';
import { ContactsRoutingModule } from './contacts-routing.module';
import { ListComponent } from './list/list.component';
import { LicenseRootComponent } from './page/license-root/license-root.component';
import { LicenseListComponent } from './components/license-list/license-list.component';
import { LicenseListTemplateComponent } from './templates/license-list-template/license-list-template.component';
import { UserListService } from './list/list.service';

@NgModule({
  declarations: [
    ListComponent,
    LicenseRootComponent,
    LicenseListComponent,
    LicenseListTemplateComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContactsRoutingModule,
    NgbDropdownModule,
    NgbTooltipModule,
    FeatherModule.pick(allIcons),
    FormsModule,
    NgbNavModule,
    NgbTypeaheadModule,
    NgbPaginationModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  providers: [UserListService, DecimalPipe]

})
export class ContactsModule { }
