import { Routes } from "@angular/router";
import { ROUTE_NAMES } from "src/app/app-routes-names";
import { LicenseListComponent } from "./components/license-list/license-list.component";
import { LicenseRootComponent } from "./page/license-root/license-root.component";

const REDIRECT = {
    path: '',
    redirectTo: ROUTE_NAMES.LIST,
    pathMatch: 'full'
}

const List = {
    path: ROUTE_NAMES.LIST,
    component: LicenseListComponent
}


export const licenceRouteConfig: Routes = [{
    path: '',
    component: LicenseRootComponent,
    children: [List,REDIRECT]
}]