import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic404',
  templateUrl: './basic404.component.html',
  styleUrls: ['./basic404.component.scss']
})

/**
 * Basic 404 Component
 */
export class Basic404Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
