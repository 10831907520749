import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { observable, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../models/auth.models';
import { ApiService } from './api.service';
import { Util } from 'src/app/authUtils';
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/account/account.service';

@Injectable({ providedIn: 'root' })
export class AuthfakeauthenticationService {

    encryptSecretKey: any = '1234567abcdef';

    private _currentUser!: User;
    public get currentUser(): any {
        return this._currentUser;
    }
    public set currentUser(value: any) {
        this._currentUser = value;
    }

    public get currentUserDetail(): User {
        return this.decodeUserData();
    }

    constructor(private http: HttpClient, private apiService: ApiService, private router: Router, private accountService: AccountService,private route: ActivatedRoute) {

        const currentUser = this.decodeUserData();
        if (currentUser) {
            this._currentUser = currentUser;
            this.accountService.userDetail.next(currentUser)
        }

    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {

        return this.apiService.getService('User/Login', {
            headers: { 'Access-UID': JSON.stringify({ email: email, password: password }) },
            withCredentials: false
        })

            .pipe(map(res => {
                if (!res._isSucceed) {
                    throw new Error(res._msg);
                }
                if (res && res._data.length && res._data[0].accessToken) {
                    this.encodeUserData(res._data[0])
                }
                this.currentUser = res._data[0];
                return res;
            }));
    }
    public encodeUserData(userData: any) {

        const currentUser = CryptoJS.AES.encrypt(JSON.stringify(userData), this.encryptSecretKey).toString();
        sessionStorage.setItem('currentUser', currentUser);
        return currentUser;
    }

    private decodeUserData() {
        let currentUser = sessionStorage.getItem('currentUser') as any;
        if (!currentUser) {
            return
        }
        const bytes = CryptoJS.AES.decrypt(currentUser, this.encryptSecretKey);
        if (bytes.toString()) {
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }

    }

    private navigateUrl(isMaintianQueryParam?: boolean, queryParams = {}) {
        if (isMaintianQueryParam) {
          return this.router.navigate(['/'], { relativeTo: this.route, queryParams });
        } else {
    
          return this.router.navigate(['/account/login'], { relativeTo: this.route, queryParams });
        }
      }

    logout() {
        // this.navigateUrl(isMaintianQueryParam, { logout: true }).finally(() => {
        //     const logout = this.apiService.getService('User/Logout').toPromise();
        //     sessionStorage.clear();
        //     sessionStorage.clear();
        //     this.currentUser = null;
        //     logout.catch((err) => throwError('There seems to be an Error'))
        //         .finally(() => this.navigateUrl(isMaintianQueryParam));
        // });
        const logout = this.apiService.getService('User/Logout').subscribe();
            sessionStorage.clear();
            sessionStorage.clear();
            this.currentUser = null;
            this.router.navigate(['/account/login']);
    }
    
    forgotPassword(qparam: any){
        return this.apiService.getService('User/ForgotPassword',{params:qparam})  .pipe(map(res => {
            if (!res._isSucceed) {
                throw new Error(res._msg);
            }
            return res;
        }));
    }

    getforgetPasswordCode(qparam: any){
        return this.apiService.getService('User/ResetPassword',{params:qparam})  .pipe(map(res => {
            if (!res._isSucceed) {
                throw new Error(res._msg);
            }
            return res;
        }));
    }

    handleError(arg0: string): (err: any, caught: Observable<unknown>) => import("rxjs").ObservableInput<any> {
        throw new Error('Method not implemented.');
    }

    refreshToken(): Observable<string> {
        return this.apiService.getService('User/RefreshToken')
            .pipe(map(res => {
                if (!res._isSucceed) {
                    throw new Error(res._msg);
                }
                if (res._data === null) {
                    throw new Error(res._msg);
                }
                if (!res._data.length) {
                    throw new Error(res._msg);
                }
                return res._data[0];
            }), map((res: any) => {
                sessionStorage.removeItem('currentUser');
                this.currentUser = res;
                this.encodeUserData(res);
                return res.accessToken;
            }));

    }

    private validateLogin() {
        if (this.currentUserDetail && this.getToken()) {
            return true;
        }
        return false;
    }
    isLoggedIn() {

        if (Util.isNullOrUndefined(this.currentUser)) {
            this.currentUser = this.decodeUserData();
        }
        return this.validateLogin();
    }

    getToken() {
        return this.currentUserDetail.accessToken;
    }
}
