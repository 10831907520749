import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return of(null).pipe(mergeMap(() => {
      if (request.url.includes('/User/Login')) {
        if (request.method === 'GET') {
          const headers = request.clone().headers.get('Access-UID');
          if (headers) {
            request = this.keyEncryption(request, headers);
          } else {
            request = request.clone({
              headers: new HttpHeaders()
            });
          }
        }

        return next.handle(request);
      }
      else if (request.url.includes('/User/ChangePassword')) {
        if (request.method === 'GET') {
          const headers = {
            oldPassword: request.clone().params.get("oldPassword"),
            newPassword: request.clone().params.get("newPassword")
        }
          if (headers) {
            request = this.keyChangePasswordEncryption(request, headers);
          } else {
            request = request.clone({
              headers: new HttpHeaders()
            });
          }
      }
    }
      return next.handle(request);
    }))
  }

  private dKey(data: any){
    const hash = CryptoJS.algo.SHA512.create();
    let dKey: any;
    dKey = CryptoJS.PBKDF2(data,
      'Fr@m3t3ch@W@',
      {
        keySize: 256 / 32,          // 2^8 bits = 32bytes(length->32)
        iterations: 10000,          // no of iteration
        hasher: hash as any         // hash
      });
    dKey = CryptoJS.enc.Hex.stringify(dKey);
    return dKey;
  }

  private keyEncryption(request: any, qparams: any) {
    let dKey: any;
    let userData = JSON.parse(qparams)
    dKey = this.dKey(userData.password);

    if (request.method === 'POST') {
      return request.clone({
        headers: new HttpHeaders(),
        body: dKey
      });
    }
    else {
      return request.clone({
        params: new HttpParams().set('emailID', userData.email).set('password', dKey),
        headers: new HttpHeaders(),
        body: dKey
      });
    }

  }
  private keyChangePasswordEncryption(request: any, qparams: any) {
    let dKey: any;
    let dKey2: any;

    dKey = this.dKey(qparams.oldPassword);
    dKey2 = this.dKey(qparams.newPassword);
    if (request.method === 'POST') {
      return request.clone({
        headers: new HttpHeaders(),
        body: dKey
      });
    }
    else {
      return request.clone({
        params: new HttpParams().set('oldPassword', dKey).set('newPassword', dKey2),
        // headers: new HttpHeaders(),
        body: dKey
      });
    }

  }
}
