import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/shared/components/notification/notification.service';
import * as CryptoJS from 'crypto-js';
import { ChangePasswordService } from './change-password.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm!: FormGroup;
  submitted: boolean = false;
  changePasswordDestroyer$: any = new Subject();

  showNavigationArrows: any;
  loginForm!: FormGroup;
  error = '';
  returnUrl!: string;
  layout_mode!: string;
  fieldTextType!: boolean;
  LoginDestroyNotifier$: any = new Subject();
  isInvalid: boolean = false;
  submit: boolean = false;
  matchPassword: boolean = false;
  isVisible: boolean = false;
  passwordVisible: any = {"oldPassword": false, "newPassword": false, "currentPassword": false };

  constructor(private formBuilder: FormBuilder, private router: Router, private changePasswordService: ChangePasswordService
    , private route: ActivatedRoute, private notificationService: NotificationService, private modalService: NgbModal
  ) { }

  ngOnInit(): void {

    this.changePasswordForm = this.formBuilder.group({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]),
      confirmNewPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]),
    });

    this.changePasswordForm.valueChanges.subscribe(res => {
      console.log(res, "res")
    });
  }

  get f() { return this.changePasswordForm.controls; }

  navigateTo(url: string) {
    this.router.navigateByUrl(url)
      .catch(e => throwError(e))
      .finally();
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  visiblePassword(property: string,value: boolean) {
    this.passwordVisible[property] = value;
    // let passwordVisible = this.passwordVisible.map((x: any) => x.key == key);
    // if(passwordVisible){
    //   this.isVisible = !this.isVisible;
    //   this.passwordVisible.find((x: any) => x.key == key).isVisible = value;
    // }
  }

  getOldPassword(key: any){
    let visible: any = this.passwordVisible.map((x: any) => (x.key == key))
    if(visible){
      return this.passwordVisible.find((x: any) => (x.key == key)).isVisible 
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    if ((this.changePasswordForm.controls.newPassword.value == this.changePasswordForm.controls.confirmNewPassword.value) && (this.changePasswordForm.controls.oldPassword.value)) {
      this.matchPassword = false;
      this.changePasswordService.changePassword(this.f.oldPassword.value, this.f.confirmNewPassword.value).pipe(takeUntil(this.changePasswordDestroyer$)).subscribe((data: any) => {
        if (!data._isSucceed) {
          this.notificationService.showError("Error", data._msg);
          return;
        }
        this.notificationService.showSuccess("Success", data._msg);
        this.changePasswordForm.reset();
        this.submitted = false;
        return true;
      },
        error => {
          let msg = error.message ? error.message : error;
          if (!error.message) {
            msg = "Something went wrong, Please try again";
          }
          this.notificationService.showError("Error", msg);
        });
    } else {
      this.matchPassword = true;
    }
  }

  confirmModal(data: any) {
    this.modalService.open(data);
  }

  alertDialogs() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    } else {

    }
  }
  closeDialogs() {
    this.submit = false;
    this.changePasswordForm.reset();
  }

  ngOnDestroy() {
    this.changePasswordDestroyer$.next();
    this.changePasswordDestroyer$.complete();
  }

}
