<!-- start page title -->
<app-pagetitle title="Form wizard" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Forms Steps</h4>
            </div><!-- end card header -->
            <div class="card-body">
                <aw-wizard [navBarLayout]="'large-empty-symbols'">
                    <aw-wizard-step>
                        <ng-template awWizardStepSymbol>
                            <div class="wizard-list-item">
                                <div class="list-item">
                                    <div class="step-icon" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Basic Info">
                                        <i class="uil uil-list-ul"></i>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <div class="row">
                            <div class="text-center mb-4">
                                <h5>Seller Details</h5>
                                <p class="card-title-desc">Fill all information below</p>
                            </div>
                            <form>
                                <div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-firstname-input" class="form-label">First
                                                    name</label>
                                                <input type="text" class="form-control" id="basicpill-firstname-input">
                                            </div>
                                        </div><!-- end col -->
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-lastname-input" class="form-label">Last
                                                    name</label>
                                                <input type="text" class="form-control" id="basicpill-lastname-input">
                                            </div>
                                        </div><!-- end col -->
                                    </div><!-- end row -->

                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-phoneno-input" class="form-label">Phone</label>
                                                <input type="text" class="form-control" id="basicpill-phoneno-input">
                                            </div>
                                        </div><!-- end col -->
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-email-input" class="form-label">Email</label>
                                                <input type="email" class="form-control" id="basicpill-email-input">
                                            </div>
                                        </div><!-- end col -->
                                    </div><!-- end row -->
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="mb-3">
                                                <label for="basicpill-address-input" class="form-label">Address</label>
                                                <textarea id="basicpill-address-input" class="form-control"
                                                    rows="2"></textarea>
                                            </div>
                                        </div><!-- end col -->
                                    </div><!-- end row -->
                                </div>
                            </form>
                        </div>
                        <!-- wizard-tab -->
                        <ul class="list-inline wizard mb-0">
                            <li class="next list-inline-item float-end"><button class="btn btn-primary w-sm"
                                    awNextStep>Next</button>
                            </li>
                        </ul>
                    </aw-wizard-step>

                    <aw-wizard-step>
                        <ng-template awWizardStepSymbol>
                            <div class="wizard-list-item">
                                <div class="list-item">
                                    <div class="step-icon" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Assignee">
                                        <i class="uil uil-clipboard-notes"></i>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <div class="row">
                            <div class="text-center mb-4">
                                <h5>Company Document</h5>
                                <p class="card-title-desc">Fill all information below</p>
                            </div>
                            <form>
                                <div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-pancard-input" class="form-label">PAN
                                                    Card</label>
                                                <input type="text" class="form-control" id="basicpill-pancard-input">
                                            </div>
                                        </div><!-- end col -->

                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-vatno-input" class="form-label">VAT/TIN
                                                    No.</label>
                                                <input type="text" class="form-control" id="basicpill-vatno-input">
                                            </div>
                                        </div><!-- end col -->
                                    </div><!-- end row -->
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-cstno-input" class="form-label">CST
                                                    No.</label>
                                                <input type="text" class="form-control" id="basicpill-cstno-input">
                                            </div>
                                        </div><!-- end col -->

                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-servicetax-input" class="form-label">Service Tax
                                                    No.</label>
                                                <input type="text" class="form-control" id="basicpill-servicetax-input">
                                            </div>
                                        </div><!-- end col -->
                                    </div><!-- end row -->
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-companyuin-input" class="form-label">Company
                                                    UIN</label>
                                                <input type="text" class="form-control" id="basicpill-companyuin-input">
                                            </div>
                                        </div><!-- end col -->

                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-declaration-input"
                                                    class="form-label">Declaration</label>
                                                <input type="text" class="form-control"
                                                    id="basicpill-declaration-input">
                                            </div>
                                        </div><!-- end col -->
                                    </div><!-- end row-->
                                </div>
                            </form>
                        </div>
                        <ul class="list-inline wizard mb-0">
                            <li class="previous list-inline-item"><button class="btn btn-primary"
                                    awPreviousStep>Previous</button>
                            </li>
                            <li class="next list-inline-item float-end"><button class="btn btn-primary w-sm"
                                    awNextStep>Next</button>
                            </li>
                        </ul>
                    </aw-wizard-step>

                    <aw-wizard-step>
                        <ng-template awWizardStepSymbol>
                            <div class="wizard-list-item">
                                <div class="list-item">
                                    <div class="step-icon" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Attached Files">
                                        <i class="uil uil-university"></i>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <div class="row justify-content-center">
                            <div class="text-center ">
                                <h5>Bank Details</h5>
                                <p class="card-title-desc">Fill all information below</p>
                            </div>
                            <form class="file_upload mb-4">
                                <div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-namecard-input" class="form-label">Name on
                                                    Card</label>
                                                <input type="text" class="form-control" id="basicpill-namecard-input">
                                            </div>
                                        </div><!-- end col -->
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Credit Card Type</label>
                                                <select class="form-select">
                                                    <option selected>Select Card Type</option>
                                                    <option value="AE">American Express</option>
                                                    <option value="VI">Visa</option>
                                                    <option value="MC">MasterCard</option>
                                                    <option value="DI">Discover</option>
                                                </select>
                                            </div>
                                        </div><!-- end col -->
                                    </div><!-- end row -->
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-cardno-input" class="form-label">Credit Card
                                                    Number</label>
                                                <input type="text" class="form-control" id="basicpill-cardno-input">
                                            </div>
                                        </div><!-- end col -->

                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-card-verification-input" class="form-label">Card
                                                    Verification Number</label>
                                                <input type="text" class="form-control"
                                                    id="basicpill-card-verification-input">
                                            </div>
                                        </div><!-- end col -->
                                    </div><!-- end row -->
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label for="basicpill-expiration-input" class="form-label">Expiration
                                                    Date</label>
                                                <input type="text" class="form-control" id="basicpill-expiration-input">
                                            </div>
                                        </div>
                                    </div><!-- end row -->
                                </div>
                            </form>
                        </div>
                        <ul class="list-inline wizard mb-0">
                            <li class="previous list-inline-item"><button class="btn btn-primary"
                                    awPreviousStep>Previous</button>
                            </li>
                        </ul>
                    </aw-wizard-step>
                </aw-wizard>
            </div>
        </div>
    </div><!-- end col -->
</div><!-- end row -->