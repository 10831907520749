import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    readonly AUTH_HEADER = "Authorization";

    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
    constructor(private AuthService: AuthfakeauthenticationService, private router: Router,) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): any {

        return next.handle(request).pipe(catchError((error: any) => {
            if ((!request.url.includes('Login') && !request.url.includes('Logout')) && error instanceof HttpErrorResponse) {
                return this.onErrorHandler(error, request, next);
            } else {
                error = error.error ? error.error._msg : error.statusText;
                return throwError(error);
            }

        }))

    }
    private onErrorHandler(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler) {
        const SESSIONEXPIRED = 401;

        const status = error.status;
        switch (status) {
            //   case (UNAUTHORIZED || TIMEOUT): {

            //     return this.handle400Error(error, req, next);
            //   }
            case SESSIONEXPIRED: {
                // should request for new token if Session Expired
                return this.handle401Error(error, req, next);
            }
            //   case FORBIDDEN: {
            //     // should route to login page if Forbidden/ Permission Denied
            //     return this.handle403Error(error, req, next);
            //   } 
            default: {
                error = error.error ? error.error._msg : error.statusText;
                break;
            }
        }
        return throwError(error);
    }
    private exit() {
        this.AuthService.logout();
    }

    private setAuthentication(request: HttpRequest<any>, token: string): HttpRequest<any> {
        const { AUTH_HEADER } = this;
        return request.clone({
            headers: request.headers.set(AUTH_HEADER, token)
        });
    }

    private handle401Error(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler) {
        console.log(error, "error")
        if (error && error.error != null && (error.error._msg.toLowerCase() == "session expired!!")) {
            this.router.navigate(['/account/login']);
        }
        else if ((error.error._msg.toLowerCase() == "unauthorized")) {
            if (!this.refreshTokenInProgress) {
                this.refreshTokenInProgress = true;
                this.refreshTokenSubject.next(null);
                return this.AuthService.refreshToken().pipe(switchMap((newToken: any) => {
                    if (newToken) {
                        this.refreshTokenSubject.next(newToken);
                        return next.handle(this.setAuthentication(req, newToken));
                    }
                    this.exit();
                    return throwError(error);
                }), catchError(errr => {
                    this.exit();
                    return throwError(errr);
                }), finalize(() => {
                    this.refreshTokenInProgress = false;
                })).toPromise();

            } else {
                return this.refreshTokenSubject.pipe(filter(token => token != null), take(1), switchMap(token => {
                    return next.handle(this.setAuthentication(req, token));
                }));
            }
        }
        else if (error.error == null) {
            this.router.navigate(['/account/login']);
        }
        return throwError(error);
    }
}