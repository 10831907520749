import { Component, OnInit, ViewChild } from '@angular/core';
import { PartialObserver, Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserListService } from '../../list/list.service';
import { NotificationService } from 'src/app/shared/components/notification/notification.service';
import { AccountService } from 'src/app/account/account.service';
import { LicenseListTemplateComponent } from '../../templates/license-list-template/license-list-template.component';

@Component({
  selector: 'app-license-list',
  templateUrl: './license-list.component.html',
  styleUrls: ['./license-list.component.scss']
})
export class LicenseListComponent implements OnInit {
  licenceListDestroyNotifier$: any = new Subject();
  licenceListData: any;
  userLoginData: any;
  isDataReceived: boolean = false;
  
  @ViewChild('licenceListTemplate') licenceListTemplate!: LicenseListTemplateComponent;
  constructor(private userListService: UserListService,public notificationService: NotificationService, private accountService: AccountService) { }

  ngOnInit(): void {

    this.accountService.userDetail.pipe(takeUntil(this.licenceListDestroyNotifier$)).subscribe((x: any) => {
      if (!x) { return; }
      this.userLoginData = x;
    });

    this.getLicenceDetails();
  }

  getLicenceDetails() {

    this.userListService.getLicenceUserList().pipe(takeUntil(this.licenceListDestroyNotifier$)).subscribe((x: any) => {
      if (!x) { return; }
      this.isDataReceived = true;
      this.licenceListData = x._data;
    });

  }
  userDetails(data: any) {
    const onSuccess = (res: any) => {
      if(res._isSucceed){
        this.notificationService.showSuccess('Success', res._msg);
        this.getLicenceDetails();
      }else{
        this.notificationService.showError('Error', res._msg);
      }
    };
    const onError = (res: any) => {
      this.notificationService.showError("Error", res._msg);
      throwError(res);
    };
    const observer: PartialObserver<any> = {
      next: onSuccess,
      error: onError,
      complete: () => { }
    };

    this.userListService.changeLicenceDetails(data).pipe(takeUntil(this.licenceListDestroyNotifier$)).subscribe(observer);
  }

  ngOnDestroy() {
    this.userListService.searchTerm = '';
    this.licenceListDestroyNotifier$.next();
    this.licenceListDestroyNotifier$.complete();
  }
}
