import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { AccountService } from 'src/app/account/account.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar Component
 */
export class TopbarComponent implements OnInit {

  mode: string | undefined;
  element: any;
  flagvalue: any;
  cookieValue: any;
  countryName: any;
  valueset: any;
  userDetailsDestroyNotifier$: any = new Subject();

  @Output() mobileMenuButtonClicked = new EventEmitter();
  userLoginData: any = [];

  constructor(
    private router: Router,
    public _cookiesService: CookieService,
    public translate: TranslateService,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    private accountService: AccountService) { }

  @Output() settingsButtonClicked = new EventEmitter();

  ngOnInit(): void {

    this.accountService.userDetail.pipe(takeUntil(this.userDetailsDestroyNotifier$)).subscribe((x: any) => {
      if (!x) { return; }
      this.userLoginData = x;
    });

  }
  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    this.authFackservice.logout();
  }

  ngOnDestroy() {
    this.userDetailsDestroyNotifier$.next();
    this.userDetailsDestroyNotifier$.complete();
  }

}
