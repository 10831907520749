<!-- start page title -->
<app-pagetitle title="Advance Tables" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header justify-content-between d-flex align-items-center">
                <h4 class="card-title">Advanced Table</h4>
                <a href="javascript:void(0);" class="btn btn-sm btn-soft-secondary">Docs <i
                        class="mdi mdi-arrow-right align-middle"></i></a>
            </div><!-- end card header -->
            <div class="card-body">
                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6">
                        <div class="dataTables_length" id="tickets-table_length"><label
                                class="d-inline-flex align-items-center">Show
                                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                    [(ngModel)]="service.pageSize" class="form-control form-control-sm mx-2">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> entries</label></div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label
                                class="d-inline-flex align-items-center">Search:
                                <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm"></label></div>
                    </div>
                    <!-- End search -->
                </div>
                <table id="datatable" class="table table-bordered dt-responsive nowrap w-100 datatables">
                    <thead>
                        <tr>
                            <th (click)="sort('name')" [ngClass]="{'asc': isDesc && column == 'name', 'desc': !isDesc && column == 'name'}">Name</th>
                            <th (click)="sort('position')" [ngClass]="{'asc': isDesc && column == 'position', 'desc': !isDesc && column == 'position'}">Position</th>
                            <th (click)="sort('office')" [ngClass]="{'asc': isDesc && column == 'office', 'desc': !isDesc && column == 'office'}">Office</th>
                            <th (click)="sort('age')" [ngClass]="{'asc': isDesc && column == 'age', 'desc': !isDesc && column == 'age'}">Age</th>
                            <th (click)="sort('date')" [ngClass]="{'asc': isDesc && column == 'date', 'desc': !isDesc && column == 'date'}">Start date</th>
                            <th (click)="sort('salary')" [ngClass]="{'asc': isDesc && column == 'salary', 'desc': !isDesc && column == 'salary'}">Salary</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let table of tables$ | async;let i=index">
                        <tr>
                            <td>
                                <ngb-highlight [result]="table.name" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                <ngb-highlight [result]="table.position" [term]="service.searchTerm">
                                </ngb-highlight>
                            </td>
                            <td>
                                <ngb-highlight [result]="table.office" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                <ngb-highlight [result]="table.age | number" [term]="service.searchTerm">
                                </ngb-highlight>
                            </td>
                            <td>
                                <ngb-highlight [result]="table.date" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                            <td>
                                <ngb-highlight [result]="table.salary" [term]="service.searchTerm"></ngb-highlight>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            Showing
                            {{service.startIndex}} to
                            {{service.endIndex}} of {{service.totalRecords}}
                            entries
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-right float-md-end pagination-rounded">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                [pageSize]="service.pageSize">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->
                </div>

                

                <div class="row">
                    <div class="col-md-4">
                       <!-- <input *ngIf="config.filtering" placeholder="Filter all columns"
                             [ngTableFiltering]="config.filtering"
                             class="form-control"
                             (tableChanged)="onChangeTable(config)" />  -->
                    </div>
                  </div>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->

        <div class="card">
            <div class="card-header justify-content-between d-flex align-items-center">
                <h4 class="card-title">Editable Table</h4>
                <a href="https://github.com/akveo/ng2-smart-table" target="_blank" class="btn btn-sm btn-soft-secondary">Docs <i
                        class="mdi mdi-arrow-right align-middle"></i></a>
            </div><!-- end card header -->
            <div class="card-body">
                <ng2-smart-table [settings]="settings" [source]="editableTable"></ng2-smart-table>
            </div>
        </div>
    </div>
    <!-- end col -->
</div>
<!-- end row -->