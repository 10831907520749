import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { licenceRouteConfig } from './licence-route-configue';


@NgModule({
    imports: [RouterModule.forChild(licenceRouteConfig)],
    exports: [RouterModule]
})

export class ContactsRoutingModule { }
