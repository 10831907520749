import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class NotificationService {
  toasts: any[] = [];
  toastList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  messageClass = {
    class : 'bg-success text-center text-white'
  }
  constructor() { }


  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
  removeAll(){
    this.toasts = [];
  }

  show(Header:any,message:any,delay?:number){

    switch (Header) {
      case 'Success':
        this.messageClass.class = 'bg-success text-center text-white';
        break;
        case 'Error':
          this.messageClass.class = 'bg-danger text-center text-white';
          break;
    
      default:
        break;
    }
   
    const options = { classname: this.messageClass.class, delay: delay ? delay : 0 };
    this.toasts.push({ textOrTpl : message, ...options });
    this.toastList.next(this.toasts)
  }

  showSuccess(Header:any,message:any,delay?:number){
    this.toasts = [];

    this.messageClass.class = 'bg-success text-center text-white';
   
    const options = { classname: this.messageClass.class, delay: delay ? delay : 0 };
    this.toasts.push({ textOrTpl : message, ...options });
    this.toastList.next(this.toasts);
  }
  showError(Header:any,message:any,delay?:number){
    this.toasts = [];

    this.messageClass.class = 'bg-danger text-center text-white';
   
    const options = { classname: this.messageClass.class, delay: delay ? delay : 0 };
    this.toasts.push({ textOrTpl : message, ...options });
    this.toastList.next(this.toasts);
  }
}
