import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';
import { Util } from 'src/app/authUtils';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authfackservice: AuthfakeauthenticationService
    ) { }

    private tokenValidation(token: string) {
        return Util.isNullOrUndefined(token);
      }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let token: any = "";
            const currentUser: any = this.authfackservice.currentUser;
            if (!(request.url.endsWith('/Login'))) {
                if(currentUser && currentUser.accessToken){
                    request = request.clone({
                        setHeaders: {
                            Authorization: `${currentUser.accessToken}`
                        },
                        headers: request.headers.set("Access-UID",currentUser.accessID)
                    });
                }
                return next.handle(request);
            }

            if (!this.authfackservice.isLoggedIn()) {
              return next.handle(request);
            }
            token = this.authfackservice.getToken();
            if (!!this.tokenValidation(token)) {
              return next.handle(request);
            }
        return next.handle(request);
    }
}
