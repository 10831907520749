<div class=" ">
    <div class="bg-overlay bg-white"></div>
    <div class="container">
        <div class="d-flex flex-column px-3 pt-4">
            <div class="row justify-content-center my-auto">
                <div class="col-md-8 col-lg-6 col-xl-4">

                    <div class="text-center  py-5">
                        <div class="mb-3 mb-md-3">
                            <a routerLink="/" class="d-block auth-logo">

                            </a>
                            <!-- <img src="./assets/images/AWA_GFI_logo.png" style="height: 75px;
                            width: 323px;" alt=""> -->
                        </div>
                        <div class="mb-4">
                            <h5>Change Password</h5>
                        </div>
                        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">

                            <div class="form-floating form-floating-custom mb-4">
                                <input [type]="!passwordVisible.oldPassword ? 'password' : 'text'" class="form-control" id="input-oldPassword"
                                    formControlName="oldPassword"
                                    [ngClass]="{ 'is-invalid': (submitted && f.oldPassword.errors) || (f.oldPassword.touched && f.oldPassword.errors) }"
                                    placeholder="Enter Old Password">
                                <label for="input-oldPassword">Current password </label>
                                <span class="form-floating-icon ">
                                    <i class="fa fa-key"></i>
                                </span>
                                <div class="eye">
                                    <i *ngIf="!passwordVisible.oldPassword" class="bi fa-lg bi-eye-slash-fill" (click)="visiblePassword('oldPassword',true)"></i>
                                    <i *ngIf="passwordVisible.oldPassword" class="fa fa-lg fa-eye" id="togglePassword" (click)="visiblePassword('oldPassword',false)"></i>
                                </div>
                                <div *ngIf="f.oldPassword.errors" class="invalid-feedback" align="left">
                                    <div *ngIf="!matchPassword && f.oldPassword.errors.required">Current password is Required</div>
                                    <div *ngIf="!matchPassword && f.oldPassword.errors.pattern">Please enter valid email</div>
                                </div>
                            </div>



                            <div class="form-floating form-floating-custom mb-4">
                                <input [type]="!passwordVisible.newPassword ? 'password' : 'text'" id="input-newPassword" class="form-control" formControlName="newPassword"
                                    [ngClass]="{ 'is-invalid': (submitted && f.newPassword.errors) || (f.newPassword.touched && f.newPassword.errors) }"
                                    placeholder="Enter password" aria-label="newPassword"
                                    aria-describedby="password-addon">
                                <label for="input-newPassword">New password</label>
                                <div class="form-floating-icon">
                                    <i class="fa fa-key" aria-hidden="true"></i>
                                </div>
                                <div class="eye">
                                    <i *ngIf="!passwordVisible.newPassword" class="bi fa-lg bi-eye-slash-fill" (click)="visiblePassword('newPassword',true)"></i>
                                    <i *ngIf="passwordVisible.newPassword" class="fa fa-lg fa-eye" id="togglePassword" (click)="visiblePassword('newPassword',false)"></i>
                                </div>
                                <div *ngIf="f.newPassword.errors" class="invalid-feedback" align="left">
                                    <span *ngIf="!matchPassword && f.newPassword.errors.required">New password is Required</span>
                                    <span *ngIf="!matchPassword && f.newPassword.errors.minlength">Password should contain minimum 8
                                        charecters</span>
                                    <span *ngIf="!matchPassword && f.newPassword.errors.maxlength">Password should not exceed more then 15
                                        charecters</span>

                                </div>
                            </div>
                            <div class="form-floating form-floating-custom mb-4">
                                <input [type]="!passwordVisible.currentPassword ? 'password' : 'text'" id="input-confirmPassword" class="form-control" formControlName="confirmNewPassword"
                                    [ngClass]="{ 'is-invalid':(submitted && f.confirmNewPassword.errors) || (f.confirmNewPassword.touched && f.confirmNewPassword.errors)  }"
                                    placeholder="Enter password" aria-label="confirmNewPassword"
                                    aria-describedby="password-addon">
                                <label for="input-confirmPassword">Confirm password</label>
                                <div class="form-floating-icon">
                                    <i class="fa fa-key" aria-hidden="true"></i>
                                </div>
                                <div class="eye">
                                    <i *ngIf="!passwordVisible.currentPassword" class="bi fa-lg bi-eye-slash-fill" (click)="visiblePassword('currentPassword',true)"></i>
                                    <i *ngIf="passwordVisible.currentPassword" class="fa fa-lg fa-eye" id="togglePassword" (click)="visiblePassword('currentPassword',false)"></i>
                                </div>
                                <div *ngIf="f.confirmNewPassword.errors" class="invalid-feedback"
                                    align="left">
                                    <span *ngIf="!matchPassword && f.confirmNewPassword.errors.required">Confirm password is Required</span>
                                    <span *ngIf="!matchPassword && f.confirmNewPassword.errors.minlength">Password should contain minimum
                                        8 charecters</span> 
                                    <span *ngIf="!matchPassword && f.confirmNewPassword.errors.maxlength">Password should not exceed more
                                        then 15 charecters</span>
                                </div>
                            </div>
                            <div *ngIf="matchPassword" class="text-danger" style="text-align: left;">Passwords doesnot
                                match!!
                            </div>

                            <div class="mt-3 d-grid">
                                <button class="btn btn-primary btn-block" type="submit">confirm</button>
                            </div>

                        </form><!-- end form -->
                    </div>
                </div><!-- end col -->
            </div><!-- end row -->
            <app-notification></app-notification>


        </div>
    </div>
</div>
<ng-template #confirm_content role="document" let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Forgot Password</h5>
        <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <h5>Enter your Email address</h5>
        <form [formGroup]="changePasswordForm"><br>
            <div class="form-floating form-floating-custom mb-4">
                <input type="email" class="form-control" aria-describedby="password-addon" formControlName="email">
                <label for="input-password">email Id</label>
                <div class="form-floating-icon">
                    <i class="bi bi-envelope-exclamation"></i>
                </div>
                <span *ngIf="submit">
                    <p class="text-danger" *ngIf="changePasswordForm.controls.email?.errors?.pattern">EmailID is
                        Invalid.</p>
                    <p class="text-danger" *ngIf="changePasswordForm.controls.email?.errors?.required">Email Is required
                    </p>
                </span>
                <!-- </div> -->
            </div>
            <div>
                <span class="text-danger">*</span>
                Reset link will be sent to this Email address. Click on that to reset your password
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <a href="javascript:void(0);" class="btn btn-outline-danger" (click)="modal.dismiss('Cross click')"
            (click)="closeDialogs()"> Cancel</a>
        <a href="javascript:void(0);" class="btn btn-outline-primary" type="submit" (click)="alertDialogs()">Confirm</a>
    </div>
</ng-template>
<!-- end authentication section -->