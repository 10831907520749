import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { WidgetModule } from './widget/widget.module';
import { NotificationComponent } from './components/notification/notification.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from './components/notification/notification.service';

@NgModule({
  declarations: [
    PagetitleComponent,
    NotificationComponent
  ],
  imports: [
    CommonModule,
    WidgetModule,
    NgbToastModule
  ],
  exports: [PagetitleComponent,NotificationComponent],
  providers:[NotificationService]
})
export class SharedModule { }
